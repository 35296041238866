
<template class="diSe">
  <Transition name="el-zoom-in-top">
    <div @click="isNoShow" @touchmove.prevent @mousewheel.prevent  v-show="show" style="background:rgba(40,44,53,0.9);height: 100%;position: fixed;z-index: 18;width: 100%">
      <div  style="width: 100%;margin-top: 5vh;margin-bottom: 3vh">
        <el-image :src="require('@/assets/image/logo.png')" fit="contain" style="width: 10vh;margin: 0 auto" mode = "aspectFit"></el-image>
      </div>
      <div style="margin-top: 0;font-size: 24px;font-weight: bold;color: white">
        加盟合作信息登记
      </div>
      <div ref="contentScroll" @touchmove.stop="listTouch" @mousewheel.stop="listTouch" :style="[{width:pageState===1?'60vw':'90vw'},{height:pageState===1?'70vh':'70vh'},{padding:pageState===1?'7vh 8vw':'6vh 5vw'}]"  @click.stop style=" scrollbar-width: none; /* Firefox */-ms-overflow-style: none; /* IE and Edge */;position: relative;overflow: scroll;border-radius: 8px;margin: 26px auto 0px auto;background-color: white" class="border">
        <div  style="position: absolute;right: 10px;top: 10px;width: fit-content;z-index: 100" @click="isNoShow">
          <el-image :src="require('../../assets/image/close.png')" style="height: 15px;width: 15px"></el-image>
        </div>
<!--        <ul class="infinite-list" style="overflow: auto">-->


          <div class="inputWaiKe">
            <div class="inputTitle">店主姓名<span style="color: red">*</span> </div>
            <div class="inputValue">
              <el-input v-model="eIntentionOfChainStoresParameter.shopkeeperName" placeholder="请输入店主姓名" />
            </div>
          </div>
          <div class="inputWaiKe">
            <div class="inputTitle">经营地点<span style="color: red">*</span> </div>
            <div class="inputValue">
              <el-input v-model="eIntentionOfChainStoresParameter.businessLocation" placeholder="请输入经营地点" />
            </div>

          </div>
          <div class="inputWaiKe">
            <div class="inputTitle">投资金额<span style="color: red">*</span> </div>
            <div class="inputValue">
              <el-input v-model="eIntentionOfChainStoresParameter.investment" placeholder="请输入投资金额 单位：人民币" />
            </div>
          </div>
          <div class="inputWaiKe">
            <div class="inputTitle">手机号码<span style="color: red">*</span> </div>
            <div class="inputValue">
              <el-input v-model="eIntentionOfChainStoresParameter.phone" placeholder="请输入您的手机号" />
            </div>
          </div>
          <div class="inputWaiKe">
            <div class="inputTitle">微信号码</div>
            <div class="inputValue ChuiZhiJuZhong">
              <el-input style="flex-grow: 1" v-model="eIntentionOfChainStoresParameter.wxNumber" placeholder="请输入微信（选填）" />
              <div @click="samePhone" style="width: clamp(70px,8vw,130px);text-align: center;color: white;padding: 8px 6px;margin-left: 3px;border-radius: 3px;background-color: #318F5F;font-size: clamp(10px, 1vw, 15px)">同手机号</div>
            </div>
          </div>
          <div class="inputWaiKe">
            <div class="inputTitle">备注问题<span style="color: red">*</span> </div>
            <div class="inputValue">
              <el-input type="textarea" v-model="eIntentionOfChainStoresParameter.notes" placeholder="请说出您的问题/备注信息" />
            </div>
          </div>
            <div style="width: 100%;height: 40px;margin-top: 20px;font-weight: bold;font-size: 16px" class="ChuiZhiJuZhong02">
              <div style="width: 25%;height: 40px;float: left"></div>
              <div style="width: 50%;height: 40px;font-weight: bold;font-size: 16px;float: left;line-height: 40px">工作经历</div>
              <div style="width: 25%;height: 40vh;float: left;height: 40px" class="ChuiZhiJuZhong02"><div style="height: 30px;width: 100%;margin-top: 5px"><el-button type="info" size="small" @click="tianJia()" round>添加+</el-button></div></div>
            </div>
            <div v-for="(item,index) in eIntentionOfChainStoresParameter.workExperience" style="width: 100%;border-radius: 7px;border: 1px solid #4F4B41;padding: 8px;margin-top: 10px">
              <div style="overflow: hidden;width: 100%">
                <div style="float:left;width: 45%;height: 40px;" class="ChuiZhiJuZhong">
                  <el-date-picker
                      v-model="item.timeOn"
                      type="date"
                      placeholder="请选择开始时间"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                      style="height: 32px;width: 140px"
                      clearable
                      disabled
                  />
                </div>
                <div style="float:left;width: 10%;height: 40px;line-height: 40px;text-align: center">
                  至
                </div>
                <div style="float:left;width: 45%;height: 40px;" class="ChuiZhiJuZhong">
                  <el-date-picker
                      v-model="item.timeEnd"
                      type="date"
                      placeholder="请选择结束时间"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                      style="height: 32px;width: 140px"
                      clearable
                      disabled
                  />
                </div>
              </div>
              <div style="width: 100%;margin: 10px auto  auto;border-radius: 10px;border: 1px solid #E3E3E3;padding: 10px;text-align: left">
                {{item.content}}
              </div>
              <div style="width: 100%;height: 35px;line-height: 35px;text-align: center;margin-top: 20px" class="yiChu">
                <div style="width: 50%;height: 35px;float: left;">
                  <el-popconfirm
                      confirm-button-text="确认"
                      cancel-button-text="取消"
                      :icon="InfoFilled"
                      icon-color="#626AEF"
                      title="确认删除该记录?"
                      @confirm="deleteJingLi(index)"
                  >
                    <template #reference>
                      <div style="width: 90%;height: 35px;line-height: 35px;text-align: center;background-color: #F5F5F5;border-radius: 8px;margin: 0px auto;">删除</div>
                    </template>
                  </el-popconfirm>
                </div>
                <div style="width: 50%;height: 35px;float: left;">

                  <div style="width: 90%;height: 35px;line-height: 35px;text-align: center;background-color: #FFCA28;border-radius: 8px;margin: 0px auto;" @click="open(index)">修改</div>
                </div>
              </div>
            </div>
          <el-button type="primary" @click="tiJiao()" style="margin: 20px auto;width: 40%">确认</el-button>
      </div>
      <div @click.stop>

      <el-dialog
          @click.stop
        v-model="dialogVisible"
        title="添加工作经历"
        width="350px"
        draggable
        @close="closeJingLi()"
      >
        <div style="width: 100%;">
          <div style="overflow: hidden;width: 100%">
            <div style="float:left;width: 45%;height: 40px;" class="ChuiZhiJuZhong">
              <el-date-picker
                  v-model="jingLi.timeOn"
                  type="date"
                  placeholder="请选择开始时间"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  style="height: 32px;width: 140px"
                  clearable

              />
            </div>
            <div style="float:left;width: 10%;height: 40px;line-height: 40px;text-align: center">
              至
            </div>
            <div style="float:left;width: 45%;height: 40px;" class="ChuiZhiJuZhong">
              <el-date-picker
                  v-model="jingLi.timeEnd"
                  type="date"
                  placeholder="请选择结束时间"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  style="height: 32px;width: 140px"
                  clearable
              />
            </div>
          </div>
          <div style="width: 90%;text-align: center;margin: 20px auto 0px auto">从事行业/岗位职责</div>
          <el-input v-model="jingLi.content" :rows="6" type="textarea" />
          <el-button type="primary" @click="onSubmit()" style="margin-top: 20px">确认</el-button>
        </div>
      </el-dialog>
      </div>

    </div>
  </Transition>
</template>

<script>
import {add} from '../../api/modules/intentionOfChainStores.js'
import zhCn from "element-plus/es/locale/lang/zh-cn";
import {
  ElLoading,
  ElMessage
} from "element-plus";

export default {
  computed: {
    data(){
      return  this.$pageState.state
    },
  },
  watch:{
    data(newValue){
      this.pageState = newValue
    }
  },
  data () {
    return {
      eIntentionOfChainStoresParameter:{
        shopkeeperName:"",
        businessLocation:"",
        investment:"",
        phone:"",
        wxNumber:"",
        notes:"",
        workExperience:[]
      },
      jingLi:{
        content:"",
        timeOn:"",
        timeEnd:""
      },
      locale: zhCn,
      dialogVisible:false,
      type:false,//弹窗模式，true为修改 false为新增
      indexNow:0,//当前正在操作的索引
      show:false,
      pageState:0,
    }
  },
  mounted() {
    this.pageState = this.$pageState.state
  },
  methods:{
    //todo 滑动穿透
    listTouch(e) {
      //scrollHeight为滑动区内部内容的高度，
      //clientHeight为滑动区的高度
      //scrollHeight大于clientHeight允许滑动
      if (!(this.$refs.contentScroll.scrollHeight > this.$refs.contentScroll.clientHeight)) {
        e.preventDefault();
      }
    },
    //todo 同手机号
    samePhone(){
      if (this.eIntentionOfChainStoresParameter.phone!==null&&this.eIntentionOfChainStoresParameter.phone!==""){
        this.eIntentionOfChainStoresParameter.wxNumber= this.eIntentionOfChainStoresParameter.phone

      }
    },
    //todo 切换页面显示状态
    isNoShow(isNot){
      this.show=!this.show;
      if (isNot===true||isNot===false){
        console.log("走")
        console.log(isNot)

        this.show = isNot
      }else {
        if (this.show){
          // this.$parent.stopMove()
        }else {
          // this.$parent.Move()
        }
      }


      return this.show
    },

    //todo 提交
    //todo 校验金额是否合法
    tiJiao(){
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      var numReg = /^[1-9][0-9]*([\.][0-9]{1,2})?$/

      var numRe = new RegExp(numReg)

      if (!numRe.test(this.eIntentionOfChainStoresParameter.investment)) {

        ElMessage({

          type: 'error',

          message: '金额请输入数字 ',

          duration: 1000,

          showClose: true,

        })
        loading.close()
        return false

      }
      add(this.eIntentionOfChainStoresParameter).then(res=>{
        if (res.data.code === 1001){
          ElMessage({
            type: "success",
            message: "提交成功",
          });
          loading.close()

          this.isNoShow();
        }else if(res.data.code === 1002){
          loading.close()

          ElMessage({
            type: "error",
            message: res.data.msg,
          });

        }
      }).catch(()=>{
        loading.close()
      })
    },
    //todo 打开弹窗界面
    tianJia(){
      //修改模式为添加
      this.type = false;
      //显示
      this.dialogVisible = true;
    },
    //todo 添加工作经历弹窗关闭
    closeJingLi(){
      this.jingLi = {
        content:"",
        timeOn:"",
        timeEnd:""
      }
    },
    //todo 确定
    onSubmit(){
      if (this.jingLi.timeEnd===""||this.jingLi.timeOn===""||this.jingLi.content===""){
        ElMessage({
          type: "error",
          message: "未填写完整",
        });
        return;
      }
      let copy = JSON.parse(JSON.stringify(this.jingLi));
      if (this.type){
        //todo 修改模式
        this.eIntentionOfChainStoresParameter.workExperience.splice(this.indexNow,1,copy);
      }else {
        //todo 添加模式
        this.eIntentionOfChainStoresParameter.workExperience.push(copy);
      }
      this.dialogVisible = false
    },
    //todo 删除
    deleteJingLi(index){
      this.eIntentionOfChainStoresParameter.workExperience.splice(index,1);
    },
    //todo 修改
    open(index){
      //修改弹窗模式为修改
      this.type = true
      //记录操作的索引
      this.indexNow = JSON.stringify(index)
      //复制出一份修改
      this.jingLi = JSON.parse(JSON.stringify(this.eIntentionOfChainStoresParameter.workExperience[index]))
      //打开弹窗
      this.dialogVisible = true;
    }
  }
}

</script>



<style scoped>

body{
  background-color: black;
}
.border{
  border: 1px solid #E3E3E3;
}
.inputWaiKe{
  width: 100%;overflow: hidden;margin-top: 16px
}
.inputTitle{
  float: left;
  width: 30%;
  height: 30px;
  text-align: left;
  line-height: 30px;
  padding-right: 10px;
  text-indent: 13px;
}
.inputValue{
  float: left;width: 70%;text-align: left
}
*{
  box-sizing: border-box
}
.infinite-list {
  height: 460px;
  padding: 0;
  margin-top: 20px;
  list-style: none;
}
.textScroll {
  display: inline-block;
  position: fixed;
  top: 46px;
  bottom: 0;
  overflow: scroll;
  right: 0;
  width: 100%;
  margin-bottom: 300px;
  z-index: 1;
  background-color:rgb(241,243,244);
}
</style>
