<template>
<!--  <Transition name="el-zoom-in-top">-->
  <Transition name="el-zoom-in-top">
    <div  v-show="show" style="border: 1px solid #ECECEC;min-height: 100%;width: 100%;background-color:#F5F5F5;position: fixed;z-index: 18;">
      <div style="position: fixed;right: 15px;top: 18px;width: fit-content;z-index: 3000" @click="isNoShow">
        <el-image :src="require('../../../assets/image/close.png')" fit="contain" style="width: 15px"></el-image>
      </div>

      <div class="ChuiZhiJuZhong" v-if="type===2" style="color: white;z-index: 1000;background-color: #686B71;position: fixed;padding: 3px 12px 3px 5px;border-radius: 66px;right: 15px;top: 66px;width: fit-content;" @click="closeCommodityInformation">
        <el-image :src="require('../../../assets/image/fanHui.png')" fit="contain" style="width: 12px;height: 12px"></el-image>
        <div style="width: fit-content;line-height: 20px;height: 20px;font-size: 12px;">返回搜索</div>
      </div>
      <div id="searchInput" style="font-size: 14px;text-indent: 6px;font-weight: bold;height: 46px;width: 100%;padding: 8px 54px 8px 12px;line-height: 30px;text-align: left">
        搜索您想了解商品的<span style="color:red;">关键词</span>
      </div>
      <div v-if="type===1">
        <div id="searchInput" style="height: 66px;width: 100%;padding: 8px 12px" class="ChuiZhiJuZhong">
          <el-input ref="refInput " id="searchInput" clearable v-model="parameter" style="flex-grow: 1;width: 100%;height: 50px;" placeholder="请输入关键词" />
          <div style="width: fit-content;padding: 0 6px 0 10px" @click="clickSearch">
            <el-image :src="require('../../../assets/image/blackSearch.png')" style="height: 24px;width: 24px;position: relative;top:3px;" fit="contain"></el-image>
          </div>
        </div>
        <div style="padding: 10px 16px;font-weight: bold;text-align: left;font-size: 12px">
          {{ nowCommodityListState?'最近商品':'搜索结果' }}
        </div>
        <div @click="getCommodityInformation(item.id)" v-for="item in nowCommodityListState?NowCommodity:commodityList" style="margin: 10px 16px 0px 26px;border-bottom: 1px solid rgba(212,212,212);padding-bottom: 10px;text-align: left;font-weight: bold">
          {{ item.name }}
        </div>
        <div style="font-size: 12px;color:#666;">{{ nowCommodityListState?NowCommodity.length===0?'不好意思，暂时没有':'':commodityList.length===0?'不好意思，没有找着哎':''}}</div>
      </div>
      <div v-if="type===2" class="textScroll">
  <!--      搜索-->

  <!--      展示商品-->
        <div >
          <div style="width: 90vw;margin: 0px auto auto;padding-bottom: 50px" >
            <el-watermark style="width: fit-content;margin: 0 auto" :gap="gap" :font="font" :content="content">
              <el-image :src="currentCommodity.pictureImage" @click="fangDa(currentCommodity.pictureImage)" style="height: 260px;margin: 0 auto;" fit="contain"></el-image>
            </el-watermark>
            <div style="width: fit-content;font-size: 20px;font-weight: bold;text-align: left;margin:20px 0 0 16px">
              {{currentCommodity.name}}
            </div>
            <div style="width: 100%;padding: 30px 20px;margin-top: 20px;background-color: white;border-radius: 5px;">
              <div style="width: 100%;">
                <div class="leftBorder" style="width: fit-content;font-size: 15px;font-weight: bold;text-align: left;text-indent: 6px">
                  商品介绍
                </div>
                <div style="width: 100%;padding-left: 33px;text-align: left;margin-top: 10px">
                  {{currentCommodity.introduce}}
                </div>
              </div>
              <div style="width: 100%;margin-top: 30px" v-if="currentCommodity.effect!==null&&currentCommodity.effect!==''">
                <div class="leftBorder" style="color: #402626;width: fit-content;font-size: 15px;font-weight: bold;text-align: left;text-indent: 6px">
                  功效
                </div>
                <div style="width: 100%;padding-left: 33px;text-align: left;margin-top: 10px">
                  {{currentCommodity.effect}}
                </div>
              </div>
<!--              吃法-->
              <div style="width: 100%;margin-top: 30px" v-if="currentCommodity.steps.length>0">
                <div class="leftBorder" style="color: #402626;width: fit-content;font-size: 15px;font-weight: bold;text-align: left;text-indent: 6px">
                  看看咋吃
                </div>
                <div style="font-size: 20px;font-weight: bolder;text-align: center;margin-top: 20px">
                  {{currentCommodity.eatingMethodName}}
                </div>
                <div style="width: 85.5vw;text-align: left;margin-top: 10px;position: relative;left: -20px; transform: translate(2.6%, 0%);padding: 3vw;border-radius: 5px;background-color: #FAF2DF" >
                  <div style="width: 76vw;height: 33vw;margin: 10px auto 0 auto" v-if="currentCommodity.eatingMethodVideoUrl">
                    <video-player
                        style="width: 100%;height: 100%"
                        :src="currentCommodity.eatingMethodVideoUrl"
                        :controls="true"
                        :autoplay="false"
                        :loop="true"

                    >
                      <!--                    相关事件-->
                      <!--                    @mounted="..."-->
                      <!--                    @ready="..."-->
                      <!--                    @play="..."-->
                      <!--                    @pause="..."-->
                      <!--                    @ended="..."-->
                      <!--                    @seeking="..."-->
                    </video-player>
                  </div>

                  <div style="padding: 10px 10px">
<!--                    食材-->
                    <div>
                      <div style="overflow: hidden">
                        <div style="float: left;width: 30px;height: 30px">
                          <el-image :src="require('../../../assets/image/search/shiCai.png')" style="width: 100%;height: 100%"></el-image>
                        </div>
                        <div style="float: left;height: 30px;line-height: 30px;font-size: 15px;font-weight: bold">
                          食材准备
                        </div>
                      </div>
                      <div style="padding-left: 15px;padding-top: 10px">
                        {{ currentCommodity.eatingMethodFoodIngredients }}
                      </div>
                    </div>

                    <div style="margin-top: 18px;border-top: dashed 2px black;padding-top: 8px" v-for="(item,index) in currentCommodity.steps">
                      <div style="font-family: maoBiZi;font-weight: bolder;font-size: 24px;line-height: 24px;color: #F95B5A">{{ index+1+"." }}</div>
                      <div v-if="item.imageUrl" style="max-height: 38vw;max-width: 76vw;margin: 2px auto 3px auto;overflow: hidden" class="ChuiZhiJuZhong">
                        <el-image fit="contain" :src="item.imageUrl" style="height: 38vw;max-width: 76vw;"></el-image>
                      </div>

                      <div style="padding-left: 15px">
                        {{
                          item.text
                        }}
                      </div>
                    </div>
<!--                    <div style="margin-top: 18px;border-top: dashed 2px black;padding-top: 8px">-->
<!--                      <div style="font-family: maoBiZi;font-weight: bolder;font-size: 24px;line-height: 24px;color: #F95B5A">2.</div>-->
<!--                      <div style="padding-left: 15px">-->
<!--                        &lt;!&ndash;                        <div><el-image></el-image></div>&ndash;&gt;-->
<!--                        先调鱼香酱汁：两勺生抽、两勺香醋、一勺耗油、一勺白糖、一勺淀粉、半-->
<!--                        碗清水搅拌均匀-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
<!--        下单按钮-->
        <xinXiCaiJi :info="'下单当前'" :gwOfficialWebsiteProductId="currentCommodity.id"></xinXiCaiJi>
      </div>
      <popUpNotification ref="yuLan">
        <el-watermark :gap="gap" :font="font" :content="content">

          <div class="ChuiZhiJuZhong" style="height: 100%" @click="yuLan.isNoShow()">
            <el-image style="width: 100%;margin: 0 auto" :src="reviewImageUrl">
            </el-image>
          </div>
        </el-watermark>
      </popUpNotification>
      <el-image-viewer @touchmove.prevent  @mousewheel.prevent @close="picturePreviewKaiGuan= false" v-if="picturePreviewKaiGuan" style="width: 100px; height: 100px" :url-list="[nowUrl]" />

    </div>
  </Transition>

</template>
<script setup>
// import {ElVideo} from 'element-plus'
import { VideoPlayer } from '@videojs-player/vue'
import { inject } from 'vue';

import 'video.js/dist/video-js.css'
import popUpNotification from '@/components/util/popUpNotification/popUpNotification.vue'
import {ref, onMounted, watch,reactive} from "vue";
import {gwGetList,gwGetCommodityInformation} from "../../../api/modules/officialWebsiteProduct.js";
import {ElLoading, ElMessage} from "element-plus";
import xinXiCaiJi from '@/components/util/xinXiCaiJi/XinXiCaiJi.vue'
//todo 视频预览属性
const video = ref({
  videoUrl:"https://wei-cheng-img-list.oss-cn-beijing.aliyuncs.com/3a85696cb79525d59472fc87532dd9e6.mp4",
  posterUrl:"https://wei-cheng-img-list.oss-cn-beijing.aliyuncs.com/bingShuangPiFeng.png"
})
//todo 图片预览组件开关
const picturePreviewKaiGuan = ref(false)
const nowUrl = ref();
//todo 打开图片预览组件
const fangDa = (url) =>{
  nowUrl.value = url;
  picturePreviewKaiGuan.value= true
}
//水印参数 字体参数 文字内容
const font = reactive({
  color: 'rgba(0, 0, 0, .20)',
  fontSize: 16
})
const gap = ref([40, 40])
const content = ref(['河北省草承绿色食品有限公司','微信公众：草承生态'])
//todo 放大图片
const reviewImageUrl = ref("");
const yuLan = ref();
const fangDaImage=(url)=>{
  reviewImageUrl.value= url;
  yuLan.value.isNoShow();
}
//todo 页面显示类型 1.搜索 2.详情
const type = ref(1)
const show = ref(false);
//当前展示商品信息
const currentCommodity = ref("")
//关键词
const parameter = ref("")
//是否展示最近新增商品列表信息
const nowCommodityListState = ref(true)
const isNoShow = ()=>{
  show.value=!show.value
  if (show.value){
    // stopMove()
  }else {
    // Move()
  }
}
 watch(inject('pageState'),(newVal,oldValue)=>{
 console.log("lll")

})
watch()
  onMounted(()=>{

    //todo 获取默认商品列表
    getCommodity("")

  })
const NowCommodity = ref([]);

const commodityList = ref([])
//点击搜索
const clickSearch=()=>{
  if (parameter.value===null ||parameter.value===''){
    console.log(parameter.value)
    return;
  }
  getCommodity(parameter.value)
}
//获取商品列表根据关键词
const getCommodity=(name)=>{
  gwGetList({"parameter": {"name":name}}).then(res=>{
    if(res.data.code===1001){
      if (name===undefined||name===null||name===""){
        //查询关键词参数为空时，将数据放于最新推荐
        NowCommodity.value = res.data.body;
      }else {
        commodityList.value = res.data.body;
        nowCommodityListState.value = false
      }
    }else {
      ElMessage.error(res.data.msg)
    }
  }).catch(()=>{
    ElMessage.error("对不起，数据获取失败，请您反馈。。")
  })
}
//todo 获取商品详细信息根据id
const getCommodityInformation= async (id)=>{
  const loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await gwGetCommodityInformation({"id":id}).then(res=>{
    if (res.data.code === 1001){
      currentCommodity.value = res.data.body;
      type.value = 2
    }else {
      ElMessage.error(res.data.msg)
    }
    loading.close();
  }).catch(()=>{
    ElMessage.error("服务暂不可用")
    loading.close();

  })
}
//todo 关闭商品详情页
const closeCommodityInformation=()=>{
  currentCommodity.value = ""
  type.value = 1
}
//停止父组件页面滚动
const stopMove=()=>{
  let m = function(e){e.preventDefault();};
  document.body.style.overflow='hidden';
  document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
}
//开启父组件页面滚动
const Move=()=>{
  let m =function(e){e.preventDefault();};
  document.body.style.overflow='';//出现滚动条
  document.removeEventListener("touchmove",m,{ passive:true });
}
defineExpose({isNoShow})
</script>
<style>
  #searchInput .el-input .el-input__wrapper{
    padding-left: 20px;
    border-radius: 0;
  }
  .infinite-list {
    height: 100%;
    padding: 0;
    margin-top: 20px;
    list-style: none;
    overflow-y:hidden
  }
  .textScroll {
    display: inline-block;
    position: fixed;
    top: 46px;
    bottom: 0;
    overflow: scroll;
    right: 0;
    width: 100%;
    margin-bottom: 30px;
    z-index: 1;
    background-color:rgb(241,243,244);
  }
  @font-face {
    font-family: "maoBiZi";
    src: url('../../../assets/file/font/字魂白鸽天行体(商用需授权).ttf');
    font-weight: normal;
    font-style: normal;
  }
</style>
