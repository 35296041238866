import service from "../request.js";

//删除文件
export function deleteFile(data) {
    return service({
        method: "GET",
        url: "/oss/delete",
        params: data,
        isNotToken:true
    });
}


//删除文件根据路径
export function deleteFileByUrl(data) {
    return service({
        method: "GET",
        url: "/oss/delete/by/url",
        params: data,
        isNotToken:true

    });
}
//上传文件
export function addFile(data) {
    return service({
        method: "POST",
        url: "/oss/add/file",
        data: data,
        isNotToken:true

    });
}
