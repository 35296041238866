import {
  createRouter,
  createWebHashHistory
} from "vue-router";
import IntentionOfChainStores from '@/components/intentionOfChainStores/intentionOfChainStores'
const router = createRouter({
  history:createWebHashHistory(),
  routes: [
    {
      // 公司简介
      path: '/',
      name: 'companyProfile',
      component:() => import("../components/companyProfile/companyProfile.vue")
    },
    {
      // 项目介绍
      path: '/projectDetails',
      name: 'projectDetails',
      component:() => import("../components/util/projectDetails/projectDetails.vue")
    },
    {
      // 专利详解
      path: '/patentExplanation',
      name: 'patentExplanation',
      component:() => import("../components/util/patentExplanation/patentExplanation.vue")
    },
    {
      //
      path: '/intentionOfChainStores',
      name: 'intentionOfChainStores',
      component: IntentionOfChainStores
    },
    {//同类产品对比
      path: '/contrast',
      name: 'contrast',
      component:() => import("../components/contrast/contrast.vue")
    },
    {//市场分析
      path: '/marketAnalysis',
      name: 'marketAnalysis',
      component:() => import("../components/marketAnalysis/marketAnalysis.vue")
    },
    {//我们具备的条件
      path: '/conditionsMet',
      name: 'conditionsMet',
      component:() => import("../components/conditionsMet/conditionsMet.vue")
    },
    {//市场规划
      path: '/marketPlanning',
      name: 'marketPlanning',
      component:() => import("../components/marketPlanning/marketPlanning.vue")
    },
    {//为什么选择我们
      path: '/reasonForSelection',
      name: 'reasonForSelection',
      component:() => import("../components/reasonForSelection/reasonForSelection.vue")
    },
    {//商学院
      path: '/help',
      name: 'help',
      component:() => import("../components/help/help.vue")
    },
    {//登录
      path: '/login',
      name: 'login',
      component:() => import("../components/login/login.vue")
    },
    {//登录
      path: '/outbound',
      name: 'outbound',
      component:() => import("../components/outbound/outbound.vue")
    }
  ]
})
export default router;
