import {reactive, ref} from 'vue'
import { defineStore } from 'pinia'

// 定义页面状态（pc/移动）
export const pageState = reactive({
    state:0
})


export const fanKuiState = reactive ({
    state:0//0关闭 1.打开
});


//提示此功能需要登陆 弹窗开关
export const isNotLogin = defineStore("login",()=>{
    const a = ref(true);
    const setA=(value)=>{
        a.value = value
    }
    return {
        a,setA
    }
})

//打开搜索弹窗开关
export const isNotSearch = defineStore("search",()=>{
    const a = ref(true);
    const setA=(value)=>{
        a.value = value
    }
    return {
        a,setA
    }
})



//加盟合作弹窗开关
export const isNotJiaMeng = defineStore("jiaMeng",()=>{
    const a = ref(true);
    const setA=(value)=>{
        a.value = value
    }
    return {
        a,setA
    }
})


